import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import withBadge from 'hocs/withBadge';
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import ProfileImage from 'assets/img/bis/profile_placeholder.png';
import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone';

import {
  MdExitToApp,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import { RiMessage2Fill, RiMessage2Line } from "react-icons/ri";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';
import bn from 'utils/bemnames';
import firestore from '../../firebaseConfig';

const bem = bn.create('header');

const MdMessagesActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>0</small>,
})(MdMessage);

const initialContactData = Object.freeze({
  user_id: '',
  // name: '',
  // email: '',
  subject: '',
  message: '',
});

const initialPasswordData = Object.freeze({
  current_password: '',
  new_password: '',
  confirm_password: '',
  user_id: '',
  token: '',
});

const initialEntrantData = Object.freeze({
  first_name: '',
  last_name: '',
  company_name: '',
  company_position: '',
  contact_phone: '',
  company_email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  province: '',
  company_industry: '',
  company_phone: '',
  email: '',
  image_url: '',
});

const initialCountdown = Object.freeze({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
})

function Header() {
  const id = '1';
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const parameter_award_id = query.get('award_id');
  const action = query.get('action');
  const module = query.get('module');
  const artworkId = query.get('artwork_id');
  const surveyId = query.get('survey_id');

  const location = useLocation();
  var currentPathName = location.pathname;
  const [cookies, setCookie, removeCookie] = useCookies(['reloadHeader', 'currentUser', 'isLoggedIn', 'award_id', 'section', 'token', 'userDetails']);
  // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentUser = cookies.currentUser;
  const userDetails = cookies.userDetails;
  const reloadHeader = cookies.reloadHeader;

  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  const isLoggedIn = cookies.isLoggedIn;
  //const userRole = cookies.userRole;
  const award_id = cookies.award_id;
  const section = cookies.section;
  const token = cookies.token;
  const currentHost = window.location.hostname;

  const [userRole, setUserRole] = useState(cookies.userRole);
  const [judge, setJudge] = useState(cookies.judge);
  const [awardStatus, setAwardStatus] = useState('');

  const [singleProfile, setSingleProfile] = useState();
  const [profileData, setProfileData] = useState(initialEntrantData);
  const [passwordData, setPasswordData] = useState(initialPasswordData);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [imgFile, setImgFile] = useState('');

  let history = useHistory();
  const [user, setUser] = useState([]);
  const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(false);
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);

  const [isOpenMessageNotificationPopover, setIsOpenMessageNotificationPopover] = useState(false);
  const [isMessageNotificationConfirmed, setIsMessageNotificationConfirmed] = useState(false);

  const [isUnreadMessage, setIsUnreadMessage] = useState(false);

  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [formStatus, setFormStatus] = useState('standby');
  const [notifications, setNotifications] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);

  const [eventStart, setEventStart] = useState();
  const [eventStartTime, setEventStartTime] = useState();
  const [eventDeadLine, setEventDeadLine] = useState();
  const [timeLeft, setTimeLeft] = useState('');
  const [entryTimeLeft, setEntryTimeLeft] = useState(initialCountdown);
  const [judgingTimeLeft, setJudgingTimeLeft] = useState(initialCountdown);
  const [shortlistingTimeLeft, setShortlistingLeft] = useState(initialCountdown);
  const [reloadCount, setReloadCount] = useState(1);
  const [userType, setUserType] = useState('');
  const [maintenance, setMaintenance] = useState(false);

  const [userNotification, setUserNotification] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [eventLogo, setEventLogo] = useState('');

  const MdNotificationsActiveWithBadge = withBadge({
    size: 'md',
    color: 'primary',
    style: {
      top: -10,
      right: -10,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    children: <small>{notificationsCount}</small>,
  })(MdNotificationsActive);

  const MessageBadge = withBadge({
    size: 'md',
    color: 'primary',
    style: {
      top: -10,
      right: -10,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    children: <small>&nbsp;</small>,
  })(RiMessage2Fill);

  const getSponsorshipAgreement = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'sponsorship/agreement?award_id=' + award_id + '&user_id=' + currentUser + '&token=' + token);
  };

  const retrieveUserNotifications = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser + '/summary?status=Unread&user_id=' + currentUser + '&token=' + token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const removeCookies = () => {
    removeCookie('token', { path: '/' });
    removeCookie('userDetails', { path: '/' });
    removeCookie('awardSettings', { path: '/' });
    removeCookie('isWelcome', { path: '/' });
    removeCookie('currentUser', { path: '/' });
    removeCookie('isLoggedIn', { path: '/' });
    removeCookie('userRole', { path: '/' });
    removeCookie('judge', { path: '/' });
    removeCookie('judge_id', { path: '/' });
    removeCookie('coordinator_id', { path: '/' });
    removeCookie('isAccept', { path: '/' });
    removeCookie('isProceed', { path: '/' });
    removeCookie('section', { path: '/' });
    removeCookie('award_id', { path: '/' });
    removeCookie('cookie_section', { path: '/' });
    removeCookie('cookie_award_id', { path: '/' });
    removeCookie('isSubmit', { path: '/' });
  };

  const handleClose = (e) => {
    setShowContact(false);
  };

  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
      user_id: currentUser,
    });
  };

  const handleShowContact = (e) => {
    //e.preventDefault();
    setShowContact(true);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const toggleNotificationPopover = () => {
    setIsOpenNotificationPopover(!isOpenNotificationPopover);
    setIsOpenUserCardPopover(false);
    setIsMessageNotificationConfirmed(false);
    if (!isNotificationConfirmed) {
      setIsNotificationConfirmed(true);
    }
  };

  const handleViewMessages = () => {
    history.push('/entrant/chat');
  }


  const toggleMessageNotificationPopover = () => {
    setIsOpenMessageNotificationPopover(!isOpenMessageNotificationPopover);
    if (!isMessageNotificationConfirmed) {
      setIsMessageNotificationConfirmed(true);
    }
  };

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
    setIsOpenNotificationPopover(false);
    setIsMessageNotificationConfirmed(false);
  };

  const handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const retrieveUser = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'user/' + currentUser + '?user_id=' + currentUser + '&token=' + token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  async function submitContact(e) {
    e.preventDefault();
    if (contactData.subject != '' && contactData.message != '') {
      setFormStatus('loading');
      // if (validator.isEmail(contactData.email)) {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'inquiry' + '?user_id=' + currentUser + '&token=' + token, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify(contactData), // body data type must match "Content-Type" header
      });

      const json = await response.json();

      if (json.status == 'Success') {
        //alert("Award created successfully");
        toast.success('Message sent successful!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setUpdateEntryCommentModal(false);
        // setUpdateEntryCommentModalShow(false);
        setFormStatus('standby');

      } else {
        toast.error('Failed to Submit, please try again!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormStatus('standby');
      }
    } else {
      toast.error('Please fill up all fields!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus('standby');
    }
  }

  const retrieveAward = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/' + e + '?user_id=' + currentUser + '&token=' + token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  function deadlineTime(date, time = ' 23:59:59') {
    moment.tz.setDefault('Europe/Dublin');

    const countDownDate = moment.tz(date + time, "Europe/Dublin");
    const now = moment();
    const duration = moment.duration(countDownDate.diff(now));

    const days = duration.asDays();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();


    return {
      days: Math.floor(days),
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  function calculateTimeLeft(e) {
    const countDownDate = new Date(e).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    let timeLeft = {};

    if (distance > 0) {
      timeLeft = {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance / 1000) % 60),
      };
    }
    return timeLeft;
  }

  useEffect(() => {
    if (award_id) {
      const getAward = async () => {
        const selectedAward = await retrieveAward(award_id);
        if (selectedAward) {
          setAwardStatus(selectedAward.status);
          setEventLogo(() => selectedAward.logo);

          setEntryTimeLeft(deadlineTime(selectedAward.entry_deadline, ' 17:00:00'));
          setJudgingTimeLeft(deadlineTime(selectedAward.judging_deadline));
          setShortlistingLeft(deadlineTime(selectedAward.shortlisting_deadline));

          if (selectedAward.id) {
            setCookie('award_id', selectedAward.id, { path: '/' });
          }
        }
      };
      getAward(award_id);
    }

    if (section === 'sponsor') {
      getSponsorshipAgreement().then(response => {
        const result = response.data.data;
        if (result) {
          if (result.length > 0) {
            setCompanyLogo(() => result[0].image_url);
          }
        } else {

        }
      }).catch(() => {
        toast.error('An error occured. Please try again or contact the administrator.', {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }

    setPasswordData({
      ...passwordData,
      user_id: currentUser,
      token: token,
    });

    if (currentUser) {
      if (!(currentPathName == '/award/booking' || currentPathName == '/price-quote/summary' || currentPathName == '/award/booking/v1' || currentPathName == '/award/booking/form' || currentPathName == '/award/booking/v2' || currentPathName == '/award/book/v2' || currentPathName == '/award/book/seats/v2' || currentPathName == '/award/book/v1' || currentPathName == '/award/book/seats/v1' || currentPathName == '/account/verification' || currentPathName == '/edit-profile' || currentPathName == '/profile' || currentPathName == '/be-a-sponsor' || currentPathName == '/be-a-judge' || currentPathName == '/involvement' || currentPathName == '/logout')) {
        if (!(currentPathName == '/register' || currentPathName == '/bank/transfer' || currentPathName == '/bank/transfer/terms' || currentPathName == '/report' || currentPathName == '/award/book' || currentPathName == '/award/booking' || currentPathName == '/award/booking/v1' || currentPathName == '/award/booking/v2' || currentPathName == '/award/book/v2' || currentPathName == '/award/book/seats/v2' || currentPathName == '/price-quote/summary' || currentPathName == '/award/book/v1' || currentPathName == '/award/book/seats/v1' || currentPathName == '/award/booking/form' || currentPathName == '/award/book/form' || currentPathName == '/award/book/form/seats' || currentPathName == '/award/book/dynamic' || currentPathName == '/award/book/seats' || currentPathName == '/award/event' || currentPathName == '/booking/summary' || currentPathName == '/booking/summary/unlisted' || currentPathName == "/booking/summary/v2" || currentPathName == '/booking/details' || currentPathName == '/booking/payment/success' || currentPathName == '/booking/payment/success/v2' || currentPathName == '/booking/attendees' || currentPathName == '/booking/attendees/setup' || currentPathName == '/category/winner' || currentPathName == '/award/winner' || currentPathName == "/award/nomination" || currentPathName == '/winner/summary' || currentPathName == '/winner/payment/success' || currentPathName == '/bank/transfer' || currentPathName == '/bank/transfer/terms' || currentPathName == '/tickets' || currentPathName == '/entry/payment/success' || currentPathName == '/invoice/payment/success' || currentPathName == '/stripe')) {
          const firstPath = currentPathName.split('/')[1];
          if (firstPath != '' && firstPath) {
            if (firstPath == 'admin' && userRole != 'Admin') {
              if (section) {
                setTimeout(function () {
                  history.push('/' + section + '/home');
                }, 500);
              } else {
                removeCookies();
                window.location.href = 'http://app.businessriver.com/';
              }
            } else if (userRole == 'Admin' && firstPath != 'admin') {
              setTimeout(function () {
                history.push('/admin');
              }, 500);
            } else {
              if (section && firstPath) {
                if (firstPath != section) {
                  if (firstPath != 'admin') {
                    history.push('/' + section + '/home');
                  }
                }
              }
            }
          }
        }
      }

      const getUserNotification = async () => {
        const selectedUserNotification = await retrieveUserNotifications();
        // console.log("User Notification",selectedUserNotification.user);
        if (selectedUserNotification) {
          setUserRole(selectedUserNotification.user.role);
          setUser(selectedUserNotification.user);
          setNotifications(selectedUserNotification.notifications);
          setNotificationsCount(selectedUserNotification.notifications.length);

          if (selectedUserNotification.user.role == 'Admin') {
            if (currentPathName == '/admin/invoices') {
              if (selectedUserNotification.user.invoices_view == 0) {
                history.push('/admin');
              } else if (selectedUserNotification.user.invoices_view == 1) {
                console.log('Invoices page ready');
              } else {
                console.log('Go Back!');
              }
            } else if (currentPathName == '/admin/venues' || currentPathName == '/admin/venue' || currentPathName == '/admin/event/information') {
              if (selectedUserNotification.user.venues_view == 0) {
                history.push('/admin');
              } else if (selectedUserNotification.user.venues_view == 1) {
                console.log('Venues page ready');
              } else {
                console.log('Go Back!');
              }
            } else if (currentPathName == '/admin/venue/seating') {
              if (selectedUserNotification.user.seating_view == 0) {
                history.push('/admin');
              } else if (selectedUserNotification.user.seating_view == 1) {
                console.log('Seating page ready');
              } else {
                console.log('Go Back!');
              }
            } else if (currentPathName == '/admin/users' || currentPathName == '/admin/view-profile') {
              if (selectedUserNotification.user.users_view == 0) {
                history.push('/admin');
              } else if (selectedUserNotification.user.users_view == 1) {
                console.log('Users page ready');
              } else {
                console.log('Go Back!');
              }
            } else {
              // console.log(currentPathName);
            }
          }

          if (selectedUserNotification.user) {
            setProfileData({
              ...profileData,
              id: selectedUserNotification.user.id,
              user_id: selectedUserNotification.user.id,
              first_name: selectedUserNotification.user.first_name,
              last_name: selectedUserNotification.user.last_name,
              company_name: selectedUserNotification.user.company_name,
              company_position: selectedUserNotification.user.company_position,
              contact_phone: selectedUserNotification.user.contact_phone,
              company_email: selectedUserNotification.user.company_email,
              address_line_1: selectedUserNotification.user.address_line_1,
              address_line_2: selectedUserNotification.user.address_line_2,
              city: selectedUserNotification.user.city,
              province: selectedUserNotification.user.province,
              company_industry: selectedUserNotification.user.company_industry,
              company_phone: selectedUserNotification.user.company_phone,
              email: selectedUserNotification.user.email,
              image_url: selectedUserNotification.user.image_url,
            });
            setImgFile(selectedUserNotification.user.image_url);
          }

        }
      };
      getUserNotification();
    } else {
      if (currentPathName == '/register' || currentPathName == '/bank/transfer' || currentPathName == '/bank/transfer/terms' || currentPathName == '/report' || currentPathName == '/award/book' || currentPathName == '/price-quote/summary' || currentPathName == '/award/booking' || currentPathName == '/award/booking/v1' || currentPathName == '/award/booking/v2' || currentPathName == '/award/book/v2' || currentPathName == '/award/book/seats/v2' || currentPathName == '/award/book/v1' || currentPathName == '/award/book/seats/v1' || currentPathName == '/award/booking/form' || currentPathName == '/award/book/form' || currentPathName == '/award/book/form/seats' || currentPathName == '/award/book/seats' || currentPathName == '/award/event' || currentPathName == '/booking/summary' || currentPathName == '/booking/summary/unlisted' || currentPathName == '/booking/summary/v2' || currentPathName == '/booking/details' || currentPathName == '/booking/payment/success' || currentPathName == '/booking/payment/success/v2' || currentPathName == '/booking/attendees' || currentPathName == '/booking/attendees/setup' || currentPathName == '/category/winner' || currentPathName == '/award/winner' || currentPathName == "/award/nomination" || currentPathName == '/winner/summary' || currentPathName == '/winner/payment/success' || currentPathName == '/bank/transfer' || currentPathName == '/bank/transfer/terms' || currentPathName == '/tickets' || currentPathName == '/entry/payment/success' || currentPathName == '/invoice/payment/success') {
      } else {
        if (award_id && section) {
          history.push('/' + section + '?award_id=' + award_id);
        } else {
          const firstPath = currentPathName.split('/')[1];
          if (firstPath && firstPath != '') {

            if (parameter_award_id && parameter_award_id != '') {

              if (module === 'procurement') {
                history.push('/' + firstPath + '/login?award_id=' + parameter_award_id + '&artwork_id=' + artworkId + '&module=' + module);
              } else if (!!surveyId) {
                history.push('/' + firstPath + '/login?award_id=' + parameter_award_id + '&survey_id=' + surveyId);
              } else {
                if (firstPath != 'award' && firstPath != 'api') {
                  history.push('/' + firstPath + '/login?award_id=' + parameter_award_id);
                } else {
                  history.push('/entrant/login?award_id=' + parameter_award_id);
                }
              }
            } else {
              if (surveyId) {
                history.push('/' + firstPath + '/login?award_id=' + parameter_award_id + '&survey_id=' + surveyId);
              }

              if (module === 'procurement') {
                history.push('/' + section + '?award_id=' + award_id + '&artwork_id=' + artworkId + '&module=' + module);
              } else {
                history.push('/' + firstPath + '/login');
              }
            }
          } else {
            window.location.href = 'http://app.businessriver.com/';
          }
        }

        removeCookies();
      }
    }

  }, [currentUser, userRole, reloadHeader, award_id, reloadCount]);

  useEffect(() => {
    // Fetch chat messages of the given meeting from Firestore
    if (section === 'entrant') {
      const unsubscribe = firestore
        .collection("awards")
        .doc(award_id)
        .collection("entrants")
        .doc(currentUser)
        .onSnapshot((snapshot) => {
          const chatData = snapshot.data();
          if (chatData) {
            setIsUnreadMessage(chatData.entrant_status === 'You have unread message(s)')
          }
        });
      return () => unsubscribe();
    }
  }, [currentUser]);

  const logOut = (e) => {
    removeCookies();

    if (!(currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost")) {
      history.push('/login/v2');
    } else {
      if (action) {
        history.push('/entrant/login?award_id=' + award_id + '&section=' + section + '&action=' + action);
      } else {
        if (section && section != '') {
          if (userRole == 'Admin') {
            history.push('/admin/login');
          } else {
            if (section == 'entrant') {
              history.push('/entrant/login');
            } else if (section == 'judge') {
              history.push('/judge/login');
            } else if (section == 'coordinator') {
              history.push('/coordinator/login');
            } else if (section == 'designer') {
              history.push('/designer/login');
            } else if (section == 'printer') {
              history.push('/printer/login');
            } else if (section == 'sponsor') {
              history.push('/sponsor/login');
            } else {
              if (section) {
                history.push('/' + section + '/login?award_id=' + award_id);
              } else {
                history.push('/entrant/login?award_id=' + award_id);
              }

            }
          }
        } else {
          if (userRole == 'Admin') {
            history.push('/admin/login');
          } else {
            history.push('/entrant/login?award_id=' + award_id);
          }
        }
      }

    }

  };

  const profile = (e) => {
    if (userRole == 'Admin') {
      history.push('/profile');
    } else {
      history.push('/profile');
    }
  };

  const reloadNotification = (e) => {
    setReloadCount(reloadCount + 1);
  };

  function handleNewEvent(event) {
    console.log('LiveChatWidget.onNewEvent', event);
  }

  const handleChangePassword = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
      user_id: currentUser,
      token: token,
    });
  };

  const toggleShowPasswordUpdate = () => {
    setShowPasswordUpdate(!showPasswordUpdate);
    if (!showPasswordUpdate) {
      setIsOpenUserCardPopover(false);
      setIsOpenNotificationPopover(false);
      setIsMessageNotificationConfirmed(false);
    }
  };

  //Change Password
  async function changePasswordSubmit() {
    if (passwordData.current_password != '' && passwordData.new_password != '' && passwordData.confirm_password != '') {
      if (passwordData.new_password == passwordData.confirm_password) {
        setFormStatus('loading');
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'password/change?user_id=' + currentUser + '&token=' + token, passwordData).then((response) => {
          const success = response.data.status;
          if (success == 'Success') {
            toast.success('Password updated successfully!', {
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setReloadCount(reloadCount + 1);
            setFormStatus('standby');
            setShowPasswordUpdate(false);
          } else {
            const errors = response.data.errors;
            for (var i = 0; i < errors.length; i++) {
              toast.error(errors[i], {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setFormStatus('standby');
          }
        }).catch(() => {
          toast.error('An error occured. Please try again or contact the administrator.', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFormStatus('standby');
        });
      } else {
        toast.error('New password and Confirm password does not match!', {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormStatus('standby');
      }
    } else {
      toast.error('Please fill up all fiedls!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus('standby');
    }
  }

  return (
    <>
      <ToastContainer style={{ width: '370px' }} />
      <Navbar light expand className={bem.b('bg-white')}>
        <Container>
          <Nav navbar className='mr-2'>
            {/* {userRole == "Admin" ?
              <a href="/admin" className="navbar-brand d-block mr-auto ml-auto text-black">
                <img
                  src={Logo}
                  width="150"
                  className=""
                  alt="BIS"
                />
              </a>
              :
              <>
              <img
                src={Logo}
                width="150"
                className=""
                alt="BIS"
              />
              </>
              // <SourceLink className="navbar-brand d-block mr-auto ml-auto text-black">
              //   <img
              //     src={Logo}
              //     width="150"
              //     className=""
              //     alt="BIS"
              //   />
              // </SourceLink>
            } */}
          </Nav>
          {userRole != 'Admin' ?
            <Nav navbar className='timer-container big'>
              {/*<SearchInput />*/}
              {section === 'sponsor' ?
                <>
                  {eventLogo && eventLogo !== '' ?
                    <img src={eventLogo}
                      height='75'
                      className='mr-3'
                    />
                    :
                    null
                  }
                </>
                :
                null
              }
              {entryTimeLeft.days || entryTimeLeft.hours || entryTimeLeft.minutes || judgingTimeLeft.days || judgingTimeLeft.hours || judgingTimeLeft.minutes ?
                (
                  <>
                    {/* <p className="mb-0"><strong className='entrant-award-subheader'>SUBMISSION OF ENTRIES ENDS IN</strong></p> */}
                    {section == 'judge' && awardStatus != 'Closed' && awardStatus != 'Events' ?
                      <>
                        {judgingTimeLeft.days > 0 || judgingTimeLeft.hours > 0 || judgingTimeLeft.minutes > 0 ?
                          <>
                            <p className='mb-0'>
                              <strong className='entrant-award-subheader'>
                                JUDGING PERIOD ENDS IN
                              </strong>
                            </p>
                            <ul className='list-inline text-center'>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='days-timer'>{judgingTimeLeft.days}</span><br />
                                  <span className='timer-text'>DAYS</span>
                                </div>
                              </li>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='hours-timer'>{judgingTimeLeft.hours}</span><br />
                                  <span className='timer-text'>HOURS</span>
                                </div>
                              </li>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='mins-timer'>{judgingTimeLeft.minutes}</span><br />
                                  <span className='timer-text'>MINUTES</span>
                                </div>
                              </li>
                            </ul>
                          </>
                          :
                          <p className='mb-0'>
                            <strong className='entrant-award-subheader'>
                              JUDGING PERIOD HAS ENDED
                            </strong>
                          </p>
                        }
                      </>
                      : section == 'entrant' && awardStatus == 'Open' ?
                        <>
                          {entryTimeLeft.days > 0 || entryTimeLeft.hours > 0 || entryTimeLeft.minutes > 0 ?
                            <>
                              <p className='mb-0'>
                                <strong className='entrant-award-subheader'>
                                  SUBMISSION OF ENTRIES ENDS IN
                                </strong>
                              </p>
                              <ul className='list-inline text-center'>
                                <li className='list-inline-item justify-content-center'>
                                  <div className='timer-info'>
                                    <span className='days-timer'>{entryTimeLeft.days}</span><br />
                                    <span className='timer-text'>DAYS</span>
                                  </div>
                                </li>
                                <li className='list-inline-item justify-content-center'>
                                  <div className='timer-info'>
                                    <span className='hours-timer'>{entryTimeLeft.hours}</span><br />
                                    <span className='timer-text'>HOURS</span>
                                  </div>
                                </li>
                                <li className='list-inline-item justify-content-center'>
                                  <div className='timer-info'>
                                    <span className='mins-timer'>{entryTimeLeft.minutes}</span><br />
                                    <span className='timer-text'>MINUTES</span>
                                  </div>
                                </li>
                              </ul>
                            </>
                            :
                            <p className='mb-0'>
                              <strong className='entrant-award-subheader'>
                                SUBMISSION OF ENTRIES HAS ENDED
                              </strong>
                            </p>
                          }
                        </>
                        : section == 'coordinator' && awardStatus != 'Judging' && awardStatus != 'Closed' && awardStatus != 'Events' ?
                          <>
                            {shortlistingTimeLeft.days > 0 || shortlistingTimeLeft.hours > 0 || shortlistingTimeLeft.minutes > 0 ?
                              <>
                                <p className='mb-0'>
                                  <strong className='entrant-award-subheader'>
                                    SHORTLISTING PERIOD ENDS IN
                                  </strong>
                                </p>
                                <ul className='list-inline text-center'>
                                  <li className='list-inline-item justify-content-center'>
                                    <div className='timer-info'>
                                      <span className='days-timer'>{shortlistingTimeLeft.days}</span><br />
                                      <span className='timer-text'>DAYS</span>
                                    </div>
                                  </li>
                                  <li className='list-inline-item justify-content-center'>
                                    <div className='timer-info'>
                                      <span className='hours-timer'>{shortlistingTimeLeft.hours}</span><br />
                                      <span className='timer-text'>HOURS</span>
                                    </div>
                                  </li>
                                  <li className='list-inline-item justify-content-center'>
                                    <div className='timer-info'>
                                      <span className='mins-timer'>{shortlistingTimeLeft.minutes}</span><br />
                                      <span className='timer-text'>MINUTES</span>
                                    </div>
                                  </li>
                                </ul>
                              </>
                              :
                              <p className='mb-0'>
                                <strong className='entrant-award-subheader'>
                                  SHORTLISTING PERIOD HAS ENDED
                                </strong>
                              </p>
                            }
                          </>
                          :
                          null
                    }

                  </>
                ) : (
                  <>
                    {section == 'judge' && awardStatus != 'Closed' && awardStatus != 'Events' ?
                      <>
                        {/* <p className="mb-0"><strong className='entrant-award-subheader'>SUBMISSION OF ENTRIES ENDS IN</strong></p> */}
                        <p className='mb-0'>
                          {judge == '1' ?
                            <strong className='entrant-award-subheader'>
                              JUDGING PERIOD ENDS IN
                            </strong>
                            :
                            <strong className='entrant-award-subheader'>
                              SUBMISSION OF ENTRIES ENDS IN
                            </strong>
                          }
                        </p>
                        <ul className='list-inline text-center'>
                          <li className='list-inline-item justify-content-center'>
                            <div className='timer-info'>
                              <span className='days-timer'>--</span><br />
                              <span className='timer-text'>DAYS</span>
                            </div>
                          </li>
                          <li className='list-inline-item justify-content-center'>
                            <div className='timer-info'>
                              <span className='hours-timer'>--</span><br />
                              <span className='timer-text'>HOURS</span>
                            </div>
                          </li>
                          <li className='list-inline-item justify-content-center'>
                            <div className='timer-info'>
                              <span className='mins-timer'>--</span><br />
                              <span className='timer-text'>MINUTES</span>
                            </div>
                          </li>
                        </ul>
                      </>
                      : section == 'entrant' && awardStatus == 'Open' ?
                        <>
                          {/* <p className="mb-0"><strong className='entrant-award-subheader'>SUBMISSION OF ENTRIES ENDS IN</strong></p> */}
                          <p className='mb-0'>
                            <strong className='entrant-award-subheader'>
                              SUBMISSION OF ENTRIES ENDS IN
                            </strong>
                          </p>
                          <ul className='list-inline text-center'>
                            <li className='list-inline-item justify-content-center'>
                              <div className='timer-info'>
                                <span className='days-timer'>--</span><br />
                                <span className='timer-text'>DAYS</span>
                              </div>
                            </li>
                            <li className='list-inline-item justify-content-center'>
                              <div className='timer-info'>
                                <span className='hours-timer'>--</span><br />
                                <span className='timer-text'>HOURS</span>
                              </div>
                            </li>
                            <li className='list-inline-item justify-content-center'>
                              <div className='timer-info'>
                                <span className='mins-timer'>--</span><br />
                                <span className='timer-text'>MINUTES</span>
                              </div>
                            </li>
                          </ul>
                        </>
                        : section == 'coordinator' && awardStatus != 'Judging' && awardStatus != 'Closed' && awardStatus != 'Events' ?
                          <>
                            {/* <p className="mb-0"><strong className='entrant-award-subheader'>SUBMISSION OF ENTRIES ENDS IN</strong></p> */}
                            <p className='mb-0'>
                              <strong className='entrant-award-subheader'>
                                SHORTLISTING PERIOD ENDS IN
                              </strong>
                            </p>
                            <ul className='list-inline text-center'>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='days-timer'>--</span><br />
                                  <span className='timer-text'>DAYS</span>
                                </div>
                              </li>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='hours-timer'>--</span><br />
                                  <span className='timer-text'>HOURS</span>
                                </div>
                              </li>
                              <li className='list-inline-item justify-content-center'>
                                <div className='timer-info'>
                                  <span className='mins-timer'>--</span><br />
                                  <span className='timer-text'>MINUTES</span>
                                </div>
                              </li>
                            </ul>
                          </>
                          :
                          null
                    }
                  </>
                )
              }
            </Nav>
            :
            null
          }

          <Nav navbar className={bem.e('nav-right')}>
            {!currentUser ?
              <NavItem className='d-inline-flex'>
                <NavLink className='position-relative mr-2 text-dark'
                  href={`entrant/login?award_id=${parameter_award_id}`}>
                  Login
                </NavLink>
                <NavLink className='position-relative mr-2 text-dark'
                  href={`entrant/register?award_id=${parameter_award_id}`}>
                  Register
                </NavLink>
              </NavItem>
              :
              <NavItem className='d-inline-flex'>

                {/* {userRole != "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/">
                    Dashboard
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/admin">
                    Dashboard
                  </NavLink>
                }

                {userRole != "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/awards">
                    Awards
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/awards">
                    Awards
                  </NavLink>
                }
                {userRole == "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/entries">
                    Entries
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/entries">
                    Entries
                  </NavLink>
                }

                {userRole == "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/bookings">
                    Bookings
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/bookings">
                    Bookings
                  </NavLink>
                } */}
                {userRole == 'Admin' ?
                  <>
                    <NavLink className='position-relative mr-2 text-dark' href='/admin'>
                      Home
                    </NavLink>
                    {user ?
                      <>
                        {user.venues_view == 1 ?
                          <NavLink className='position-relative mr-2 text-dark' href='/admin/venues'>
                            Venues
                          </NavLink>
                          :
                          null
                        }
                      </>
                      :
                      null
                    }

                    {user ?
                      <>
                        {user.invoices_view == 1 ?

                          <div className='position-relative mr-2 text-dark dropdown-menu-nav' href='#'>
                            <NavLink className='position-relative p-0 text-dark' href='#'>
                              Finance
                            </NavLink>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                              <NavLink className='position-relative text-dark' href='/admin/invoices'>
                                Invoices
                              </NavLink>
                              <NavLink className='position-relative text-dark' href='/admin/price-quote'>
                                Price Quotes
                              </NavLink>
                              <NavLink className='position-relative text-dark' href='/admin/bank/transfers'>
                                Bank Transfers
                              </NavLink>
                              <NavLink className='position-relative text-dark' href='/admin/booking/forms'>
                                Booking Forms
                              </NavLink>
                            </div>
                          </div>
                          :
                          null
                        }
                      </>
                      :
                      null
                    }
                    {/* <NavLink className="position-relative mr-2 text-dark" href="/admin/artworktype">
                      Artwork
                    </NavLink> */}
                    {user ?
                      <>
                        {user.users_view == 1 ?
                          <NavLink className='position-relative mr-2 text-dark' href='/admin/users'>
                            Users
                          </NavLink>
                          :
                          null
                        }
                      </>
                      :
                      null
                    }
                    {user ?
                      <>
                        {user.judging_settings == 1 ?
                          <>
                            <div className='position-relative mr-2 text-dark dropdown-menu-nav' href='#'>
                              <NavLink className='position-relative p-0 text-dark' href='#'>
                                Judging
                              </NavLink>
                              <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                <NavLink className='position-relative text-dark' href='/admin/judges'>
                                  Judges
                                </NavLink>
                                <NavLink className='position-relative text-dark' href='/admin/coordinators'>
                                  Coordinators
                                </NavLink>
                              </div>
                            </div>
                          </>
                          :
                          null
                        }
                      </>
                      :
                      null
                    }
                  </>
                  :
                  <>
                    <NavLink className='position-relative mr-2 text-dark' href={`/${section}/home`}>
                      Home
                    </NavLink>
                  </>
                }
                {userDetails.user.admin_privileges ?
                  <>
                    {userDetails.user.admin_privileges === 'Script' && (
                      <NavLink className='position-relative mr-2 text-dark dropdown-menu-nav' href='/admin/users'>
                        <NavLink className='position-relative p-0 text-dark' href='#'>
                          Settings
                        </NavLink>
                        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                          <NavLink className='position-relative text-dark' href='/admin/scripts'>
                            Script
                          </NavLink>
                        </div>
                      </NavLink>
                    )}
                  </>
                  :
                  <>
                    {userRole == 'Admin' ?
                      <NavLink className='position-relative mr-2 text-dark' href='/admin/tickets'>
                        Tickets
                      </NavLink>
                      :
                      null
                    }
                    {userRole == 'Admin' ?
                      <>
                        <NavLink className='position-relative mr-2 text-dark dropdown-menu-nav' href='/admin/users'>
                          <NavLink className='position-relative p-0 text-dark' href='#'>
                            Settings
                          </NavLink>
                          <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                            <NavLink className='position-relative text-dark' href='/admin/project-types'>
                              Supplier Types
                            </NavLink>
                            <NavLink className='position-relative text-dark' href='/admin/scripts'>
                              Script
                            </NavLink>
                            <NavLink className='position-relative text-dark' href='/admin/sponsorship/types'>
                              Sponsorship Types
                            </NavLink>
                            <NavLink className='position-relative text-dark' href='/admin/sponsorship/features'>
                              Sponsorship Features
                            </NavLink>
                            <NavLink className='position-relative text-dark' href='/admin/blacklist'>
                              Blacklists
                            </NavLink>
                            <NavLink className='position-relative mr-2 text-dark' href='/admin/blocked-mails'>
                              Blocked Mails
                            </NavLink>
                            <NavLink className='position-relative mr-2 text-dark' href='/admin/all/surveys'>
                              Survey Export
                            </NavLink>
                          </div>
                        </NavLink>
                      </>
                      :
                      null
                    }
                  </>
                }
                {/* {userRole != "Admin" ?
                  <>
                    <NavLink className="position-relative mr-2 text-dark" href="#" onClick={handleShowContact}>
                      <img src={CostumerServiceIcon} className="mr-2" />
                      Contact Us
                    </NavLink>
                  </>
                  :
                  null
                } */}
                <Modal
                  isOpen={showContact}
                  className='modal-preview'
                  fade={false}
                  centered
                  id='edit-judge-modal'
                >
                  <ModalHeader className='text-left'>
                    CONTACT US
                    <button type='button' className='close react-modal-close' data-dismiss='modal' aria-label='Close'
                      onClick={handleClose}>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg='12'>
                        <Card>
                          <CardBody className='card_container'>
                            {/* <FormGroup>
                              <Input type="hidden" name="name" onChange={handleChange} value={user.first_name + " " + user.last_name} />
                            </FormGroup>
                            <FormGroup>
                              <Input type="hidden" name="email"  onChange={handleChange} value={user.email} />
                            </FormGroup> */}
                            <FormGroup>
                              <Input type='text' name='subject' maxLength={255} onChange={handleChange}
                                placeholder='Subject' />
                            </FormGroup>
                            <FormGroup>
                              <Input type='textarea' rows='5' name='message' onChange={handleChange}
                                placeholder='Message' />
                            </FormGroup>
                          </CardBody>
                          <CardFooter className='text-right'>
                            <button type='button' className='btn btn-main btn-main-alt mr-3' data-dismiss='modal'
                              onClick={handleClose}>Close
                            </button>
                            {formStatus == 'standby' ?
                              <button type='button' className='btn newbtntheme primary-btn'
                                onClick={submitContact}>Send</button>
                              :
                              <button type='button' className='btn newbtntheme primary-btn'
                                onClick={preventDefault}>Sending...</button>
                            }
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={showPasswordUpdate}
                  className='modal-preview'
                  fade={false}
                  centered
                // id="edit-judge-modal"
                >
                  <ModalHeader className='text-left'>
                    CHANGE PASSWORD
                    <button type='button' className='close react-modal-close' data-dismiss='modal' aria-label='Close'
                      onClick={toggleShowPasswordUpdate}>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg='12'>
                        <Card>
                          <CardBody className='card_container'>
                            <FormGroup>
                              <label htmlFor='password'>Password</label>
                              <Input type='password' name='current_password' onChange={handleChangePassword}
                                placeholder='Password' />
                            </FormGroup>
                            <FormGroup>
                              <label htmlFor='password'>New Password</label>
                              <Input type='password' name='new_password' onChange={handleChangePassword}
                                placeholder='New Password' />
                            </FormGroup>
                            <FormGroup>
                              <label htmlFor='password'>Confirm Password</label>
                              <Input type='password' name='confirm_password' onChange={handleChangePassword}
                                placeholder='Confirm Password' />
                            </FormGroup>
                          </CardBody>
                          <CardFooter className='text-right'>
                            <button type='button' className='btn btn-main btn-main-alt mr-3' data-dismiss='modal'
                              onClick={toggleShowPasswordUpdate}>Close
                            </button>
                            {formStatus == 'standby' ?
                              <button type='button' className='btn newbtntheme primary-btn'
                                onClick={changePasswordSubmit}>Save</button>
                              :
                              <button type='button' className='btn newbtntheme primary-btn'
                                onClick={preventDefault}>Saving...</button>
                            }
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                {/* <NavLink type="button" className="position-relative mr-3 text-dark" data-toggle="modal" data-target="#exampleModalLong">
                Contact Us
              </NavLink>
              <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      ...
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                  </div>
                </div>
              </div> */}
              </NavItem>
            }
            {!(userRole != 'Admin' || (userRole == 'Admin' && userDetails.user.admin_privileges)) && (
              <NavItem className='d-inline-flex'>
                <NavLink id='Popover1' className='position-relative mr-2'>
                  {isNotificationConfirmed || notificationsCount < 1 ? (
                    <MdNotificationsNone
                      size={25}
                      className='text-secondary can-click'
                      onClick={toggleNotificationPopover}
                    />
                  ) : (
                    <MdNotificationsActiveWithBadge
                      size={25}
                      className='text-secondary can-click swing infinite'
                      onClick={toggleNotificationPopover}
                    />
                  )}
                </NavLink>
                <Popover
                  placement='bottom-end'
                  isOpen={isOpenNotificationPopover}
                  toggle={toggleNotificationPopover}
                  target='Popover1'
                  className='notifications-popup'
                >
                  <PopoverBody>
                    <Notifications notificationsData={notifications} userRole={userRole} currentUser={currentUser}
                      reloadHeader={reloadNotification} />
                  </PopoverBody>
                  {/* <a href="/notifications">
                  <div className='btn newbtntheme border-left-0 border-bottom-0 border-right-0 w-100 text-center'>
                    VIEW ALL
                  </div>
                </a> */}
                </Popover>
              </NavItem>
            )}
            {/* <NavItem className="d-inline-flex">
              <NavLink id="Popover2" className="position-relative">
                {isMessageNotificationConfirmed ? (
                  <MdMessage
                    size={25}
                    className="text-secondary can-click"
                    onClick={toggleMessageNotificationPopover}
                  />
                ) : (
                  <MdMessagesActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={toggleMessageNotificationPopover}
                  />
                )}
              </NavLink>
              <Popover
                placement="bottom"
                isOpen={isOpenMessageNotificationPopover}
                toggle={toggleMessageNotificationPopover}
                target="Popover2"
              >
                <PopoverBody>
                  <Notifications notificationsData={notificationsData} />
                </PopoverBody>
                </Popover>
            </NavItem> */}
            {currentUser ?
              <NavItem>
                <NavLink id='Popover3'>
                  {/* <Avatar
                    onClick={toggleUserCardPopover}
                    className="can-click"
                    src={imgFile ? imgFile : null}
                  //src={user.avatar}
                  /> */}
                  {imgFile ?
                    <div onClick={toggleUserCardPopover} className='profile-picture-container cursor-pointer' style={{
                      backgroundImage: 'url(' + process.env.REACT_APP_URL + 'storage/user/' + imgFile + ')',
                      width: '50px',
                      height: '50px',
                    }}></div>
                    :
                    <div onClick={toggleUserCardPopover} className='profile-picture-container cursor-pointer'
                      style={{ backgroundImage: 'url(' + ProfileImage + ')', width: '50px', height: '50px' }}></div>
                  }
                </NavLink>
                <Popover
                  placement='bottom-end'
                  isOpen={isOpenUserCardPopover}
                  toggle={toggleUserCardPopover}
                  target='Popover3'
                  className='p-0 border-0'
                  style={{ minWidth: 250 }}
                >
                  <PopoverBody className='p-0'>
                    <UserCard
                      //title={user.first_name +" "+ user.last_name}
                      title={user.first_name}
                      //title="Administrator"
                      //subtitle="customercare@businessriver.com"
                      //subtitle={currentUser.user.email}
                      //text="Last updated 3 mins ago"
                      className='border-light'
                      avatar={imgFile ? imgFile : null}
                    //avatar={user.avatar}
                    >
                      <ListGroup flush>
                        {userRole != 'Admin' ?
                          <ListGroupItem tag='button' onClick={profile} action className=''>
                            <MdPersonPin style={{ fontSize: '20px' }} /> Profile
                          </ListGroupItem>
                          :
                          null
                        }
                        {/*<ListGroupItem tag="button" action className="border-light">
                        <MdInsertChart /> Stats
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdMessage /> Messages
                      </ListGroupItem>
                      */}
                        <ListGroupItem tag='button' onClick={toggleShowPasswordUpdate} action className='border-light'>
                          <MdSettingsApplications style={{ fontSize: '20px' }} /> Change Password
                        </ListGroupItem>
                        {/*<ListGroupItem tag="button" action className="border-light">
                        <MdHelp /> Help
                      </ListGroupItem>*/}
                        <ListGroupItem tag='button' onClick={logOut} action className=''>
                          <MdExitToApp style={{ fontSize: '20px' }} /> Logout
                        </ListGroupItem>
                      </ListGroup>
                    </UserCard>
                  </PopoverBody>
                </Popover>
              </NavItem>
              :
              null
            }
          </Nav>
        </Container>
        {/* {currentPathName == "/register" || currentPathName == "/bank/transfer" || currentPathName == "/bank/transfer/terms" || currentPathName == "/report" || currentPathName == "/award/book" || currentPathName == "/award/book/seats" || currentPathName == "/award/event" || currentPathName == "/booking/summary" || currentPathName == "/booking/summary/unlisted" || currentPathName == "/booking/details" || currentPathName == "/booking/payment/success" || currentPathName == "/booking/attendees" || currentPathName == "/booking/attendees/setup" || currentPathName == "/category/winner" || currentPathName == "/award/winner" || currentPathName == "/award/nomination" || currentPathName == "/winner/summary" || currentPathName == "/winner/payment/success" || currentPathName == "/bank/transfer" || currentPathName == "/bank/transfer/terms" || currentPathName == "/tickets" ?
            null
            :
            <LiveChatWidget
              license="15095595"
              visibility="maximized"
              onNewEvent={handleNewEvent}
            />
        } */}

        {/*{currentPathName == "/entrant/gallery" || currentPathName == "/entrant/gallery/selections" || currentPathName == "/entrant/gallery/timeline" ?*/}

        {/*  <LiveChatWidget*/}
        {/*    license="15095595"*/}
        {/*    visibility="maximized"*/}
        {/*    onNewEvent={handleNewEvent}*/}
        {/*  />*/}
        {/*  :*/}
        {/*  null*/}
        {/*}*/}
        {/*{awardStatus != "" ?*/}
        {/*  <>*/}
        {/*    {currentPathName == "/entrant/home" && awardStatus == "Closed" ?*/}
        {/*      <LiveChatWidget*/}
        {/*        license="15095595"*/}
        {/*        visibility="maximized"*/}
        {/*        onNewEvent={handleNewEvent}*/}
        {/*      />*/}
        {/*      :*/}
        {/*      null*/}
        {/*    }*/}
        {/*  </>*/}
        {/*  :*/}
        {/*  null*/}
        {/*}*/}

      </Navbar>
    </>
  );
}

export default Header;
