import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import BISlogoblue from 'assets/img/bis/logo2.png'
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import validator from 'validator'
import axios from 'axios';
import industries from '../utils/Industries';
import CustomToast from '../utils/CustomToast';

function BisAuthForm({ authState, children, buttonText }) {
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const { register, setValue, handleSubmit, formState: { errors } } = useForm();
  let query = useQuery();
  const email = query.get('email');
  const page = query.get('page');
  const action = query.get('action');
  const entry_id = query.get('entry_id');
  const confirm_tr_id = query.get('confirm_tr_id');
  const parameter_section = query.get('section');
  const category_id = query.get('category_id');
  const checkAwardId = query.get('award_id');
  const location = useLocation();
  const currentPathName = location.pathname;
  const currentHost = window.location.hostname;
  const currentUrl = window.location.href;
  const [section, setSection] = useState("");
  const [award_id, setAwardId] = useState(query.get('award_id'));
  const [awardReady, setAwardReady] = useState(false);
  const [formStatus, setFormStatus] = useState("standby");
  const [awardStatus, setAwardStatus] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'award_id', 'section', 'failsafe_award_id', 'userRole']);
  const currentUser = cookies.currentUser;
  const cookie_award_id = cookies.award_id;
  const cookie_section = cookies.section;
  const failsafe_award_id = cookies.failsafe_award_id;
  const userRole = cookies.userRole;

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id);
  }

  const getAwardId = async (url) => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'dashboard/award/current?url=' + url);
  }

  const postRegister = async (data) => {
    return await axios.post(process.env.REACT_APP_API_ENDPOINT + 'register', data);
  }

  const postLogin = async (data) => {
    return await axios.post(process.env.REACT_APP_API_ENDPOINT + 'login', data);
  }

  const defaultRedirect = () => {
    setTimeout(function () {
      window.location.href = "http://app.businessriver.com/";
    }, 1500);
  }

  //Cookies Handling
  const resetCookies = () => {
    removeCookie('token', { path: '/' });
    removeCookie('userDetails', { path: '/' });
    removeCookie('awardSettings', { path: '/' });
    removeCookie('isWelcome', { path: '/' });
    removeCookie('currentUser', { path: '/' });
    removeCookie('isLoggedIn', { path: '/' });
    removeCookie('userRole', { path: '/' });
    removeCookie('judge', { path: '/' });
    removeCookie('judge_id', { path: '/' });
    removeCookie('coordinator_id', { path: '/' });
    removeCookie('isAccept', { path: '/' });
    removeCookie('isProceed', { path: '/' });
    removeCookie('section', { path: '/' });
    removeCookie('award_id', { path: '/' });
    removeCookie('cookie_section', { path: '/' });
    removeCookie('cookie_award_id', { path: '/' });
    removeCookie('isSubmit', { path: '/' });
  }

  const storeSessionCookies = (data) => {
    setCookie('userDetails', JSON.stringify(data), { path: '/' });
    setCookie('awardSettings', data.user.award_settings, { path: '/' });
    setCookie('currentUser', JSON.stringify(data.id), { path: '/' });
    setCookie('userRole', data.user.role, { path: '/' });
    setCookie('token', data.token, { path: '/' });
    setCookie('isLoggedIn', true, { path: '/' });
    setCookie('judge', data.user.judge, { path: '/' });
    setCookie('judge_id', data.user.judge_id, { path: '/' });
    setCookie('coordinator_id', data.user.coordinator_id, { path: '/' });
    setCookie('cookie_award_id', award_id, { path: '/' });
    setCookie('cookie_section', section, { path: '/' });
    setCookie('award_id', award_id, { path: '/' });
    setCookie('failsafe_award_id', award_id, { path: '/' });
    setCookie('section', section, { path: '/' });
  }

  const storeAwardCookies = (id) => {
    setCookie('award_id', id, { path: '/' });
    setCookie('cookie_award_id', id, { path: '/' });
    setCookie('failsafe_award_id', id, { path: '/' });
    setTimeout(function () {
      setAwardReady(true);
    }, 1000);
  }

  //Section Redirect
  const checkSection = () => {
    if (currentPathName == "/entrant/login") {
      setSection("entrant");
    } else if (currentPathName == "/judge/login" || currentPathName == "/judge/register") {
      setSection("judge");
    } else if (currentPathName == "/coordinator/login" || currentPathName == "/coordinator/register") {
      setSection("coordinator");
    } else if (currentPathName == "/sponsor/login") {
      setSection("sponsor");
    } else if (currentPathName == "/designer/login") {
      setSection("designer");
    } else if (currentPathName == "/printer/login") {
      setSection("printer");
    } else {
      if (parameter_section) {
        setSection(parameter_section);
      } else {
        setSection("entrant");
      }
    }
  }

  const checkEntrantPortal = () => {
    setCookie('section', "entrant", { path: '/' });
    setCookie('cookie_section', "entrant", { path: '/' });

    if (action === 'entry') {
      history.push("/entrant/submit-entry");
    } else if (entry_id) {
      setTimeout(function () {
        history.push("/entrant/entry/details?entry_id=" + entry_id);
      }, 500);
    } else if (confirm_tr_id) {
      setTimeout(function () {
        history.push("/entrant/confirm/trophy-recipient?id=" + confirm_tr_id);
      }, 500);
    } else {
      if (category_id && category_id != "") {
        history.push("/entrant/category?category_id=" + category_id + "&from_website=1");
      } else {
        history.push("/entrant/home");
      }
    }
    if (page === 'price-quote') {
      history.push("/entrant/price-quote");
    }
  }

  const checkJudgePortal = () => {
    setCookie('section', "judge", { path: '/' });
    setCookie('cookie_section', "judge", { path: '/' });
    history.push("/judge/home");
  }

  const checkCoordinatorPortal = () => {
    setCookie('section', "coordinator", { path: '/' });
    setCookie('cookie_section', "coordinator", { path: '/' });
    history.push("/coordinator/home");
  }

  const checkSponsorPortal = () => {
    setCookie('section', 'sponsor', { path: '/' });
    setCookie('cookie_section', 'sponsor', { path: '/' });
    history.push("/sponsor/home");
  }

  const renderButtonText = (e) => {
    if (!buttonText && authState === STATE_BIS_LOGIN) {
      return 'Login';
    }

    if (!buttonText && authState === STATE_BIS_SIGNUP) {
      return 'Register';
    }
    return buttonText;
  }

  const goToLogin = (e) => {
    let emailParams = '';
    if (email)
      emailParams = '&email=' + email;

    if (action) {
      if (category_id && category_id != "") {
        history.push("/entrant/login?award_id=" + award_id + "&section=" + section + "&action=" + action + "&category_id=" + category_id + emailParams);
      } else {
        history.push("/entrant/login?award_id=" + award_id + "&section=" + section + "&action=" + action + emailParams);
      }
    } else {
      if (category_id && category_id != "") {
        if (award_id && section) {
          history.push("/" + section + "/login?award_id=" + award_id + "&category_id=" + category_id + emailParams);
        } else {
          history.push("/entrant/login?award_id=" + award_id + "&category_id=" + category_id + emailParams);
        }
      } else {
        if (award_id && section) {
          history.push("/" + section + "/login?award_id=" + award_id + emailParams);
        } else {
          history.push("/entrant/login?award_id=" + award_id + emailParams);
        }
      }

    }
  }

  const goToRegister = (e) => {
    let emailParams = '';
    if (email)
      emailParams = '&email=' + email;

    if (action) {
      if (category_id && category_id != "") {
        history.push("/entrant/register?award_id=" + award_id + "&section=" + section + "&action=" + action + "&category_id=" + category_id + emailParams);
      } else {
        history.push("/entrant/register?award_id=" + award_id + "&section=" + section + "&action=" + action + emailParams);
      }

    } else {
      if (category_id && category_id != "") {
        if (award_id && section) {
          if (currentPathName == "/entrant/login") {
            history.push("/entrant/register?award_id=" + award_id + "&category_id=" + category_id + emailParams);
          } else {
            history.push("/register?award_id=" + award_id + "&section=" + section + "&category_id=" + category_id + emailParams);
          }
        } else {
          history.push("/register?category_id=" + category_id + emailParams);
        }
      } else {
        if (award_id && section) {
          if (currentPathName == "/entrant/login") {
            history.push("/entrant/register?award_id=" + award_id + emailParams);
          } else {
            history.push("/register?award_id=" + award_id + "&section=" + section + emailParams);
          }
        } else {
          if (email)
            history.push("/register?email=" + email);
          else
            history.push("/register");
        }
      }
    }
  }

  const loginSubmit = async (data) => {
    setFormStatus("loading");
    if (validator.isEmail(data.email)) {

      const payload = {
        ...data,
        award_id: award_id,
        url: currentUrl
      }

      postLogin(payload).then(response => {
        const errors = response.data.errors;

        if (errors) {
          if (errors.email) {
            CustomToast.error(errors.email[0]);
          } if (errors.password) {
            CustomToast.error(errors.password[0]);
          } else {
            CustomToast.error(errors[0]);
          }
          setFormStatus("standby");
          return
        }

        const status = response.data.status;
        if (status === 'Success') {
          const data = response.data.data;
          setFormStatus("standby");
          storeSessionCookies(data);

          if (!(currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost")) {
            const domainParts = currentHost.split('.');
            const domainSection = domainParts[0];

            if (domainSection === 'entrant') {
              checkEntrantPortal();
            } else if (domainSection === 'judge') {
              checkJudgePortal();
            } else if (domainSection === 'coordinator') {
              checkCoordinatorPortal();
            } else if (domainSection === 'sponsor') {
              checkSponsorPortal();
            } else {
              checkEntrantPortal();
            }
          } else if (currentPathName == "/entrant/login") {
            checkEntrantPortal();
          } else if (currentPathName == "/judge/login") {
            checkJudgePortal();
          } else if (currentPathName == "/coordinator/login") {
            checkCoordinatorPortal();
          } else {
            if (section == "designer" || section == "printer") {
              if (section == "designer") {
                setCookie('section', "designer", { path: '/' });
                setCookie('cookie_section', "designer", { path: '/' });
                history.push("/designer/home?award_id=" + award_id + "&section=" + section);
              } else {
                setCookie('section', "printer", { path: '/' });
                setCookie('cookie_section', "printer", { path: '/' });
                history.push("/printer/home?award_id=" + award_id + "&section=" + section);
              }
            } else {
              if (action) {
                setCookie('section', section, { path: '/' });
                setCookie('cookie_section', section, { path: '/' });
                history.push("/" + section + "/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
              } else {
                if (section == "sponsor") {
                  setCookie('section', section, { path: '/' });
                  setCookie('cookie_section', section, { path: '/' });
                  history.push("/sponsor/home");
                } else {
                  if (data.user.role == "Admin") {
                    history.push("/admin");
                  } else if (award_id && section) {
                    setCookie('section', section, { path: '/' });
                    setCookie('cookie_section', section, { path: '/' });
                    history.push("/" + section + "/home");
                  } else if (award_id) {
                    history.push("/entrant/home");
                  } else if (section) {
                    setCookie('section', section, { path: '/' });
                    setCookie('cookie_section', section, { path: '/' });
                    history.push("/" + section + "/home");
                  } else {
                    history.push("/entrant/home");
                  }
                }
              }
            }
          }
        }
      }).catch(() => {
        CustomToast.defaultError();
      });
    } else {
      const message = "Please enter a valid email address";
      CustomToast.error(message);
      setFormStatus("standby");
    }
  }

  const signupSubmit = async (data) => {
    setFormStatus("loading");

    if (validator.isEmail(data.email)) {
      const payload = {
        ...data,
        award_id: award_id,
        url: currentUrl
      }
      postRegister(payload).then(response => {
        const errors = response.data.errors;

        if (errors) {
          if (errors.email) {
            CustomToast.error(errors.email[0]);
          } if (errors.password) {
            CustomToast.error(errors.password[0]);
          } else {
            CustomToast.error(errors[0]);
          }
          setFormStatus("standby");
          return;
        }

        const status = response.data.status;

        if (status === "Success") {
          const data = response.data.data;
          storeSessionCookies(data);

          if (action) {
            history.push("/" + section + "/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
          } else {

            if (!(currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost")) {
              const domainParts = currentHost.split('.');
              const domainSection = domainParts[0];

              if (domainSection === 'entrant') {
                checkEntrantPortal();
              } else if (domainSection === 'judge') {
                checkJudgePortal();
              } else if (domainSection === 'coordinator') {
                checkCoordinatorPortal();
              } else if (domainSection === 'sponsor') {
                checkSponsorPortal();
              } else {
                checkEntrantPortal();
              }
            } else if (currentPathName == "/entrant/register") {
              checkEntrantPortal();
            } else if (currentPathName == "/judge/register") {
              checkJudgePortal();
            } else if (currentPathName == "/coordinator/register") {
              checkCoordinatorPortal();
            } else {
              if (data.user.role == "Admin") {
                history.push("/admin");
              } else if (award_id && section) {
                history.push(section + "/home?award_id=" + award_id + "&section=" + section);
              } else if (award_id) {
                history.push("?award_id=" + award_id);
              } else if (section) {
                history.push(section + "/home?section=" + section);
              } else {
                if (action === 'entry') {
                  history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                } else {
                  if (category_id && category_id != "") {
                    history.push("/entrant/category?category_id=" + category_id + '&from_website=1');
                  } else {
                    history.push("/entrant/home");
                  }
                }
              }
            }

          }

          setFormStatus("standby");

        }
      }).catch(() => {
        CustomToast.defaultError();
      });
    } else {
      const message = "Please enter a valid email address";
      CustomToast.error(message);
      setFormStatus("standby");
    }

  }

  useEffect(() => {
    if (email && email != "") {
      setValue("email", email);
    }

    if (currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost") {

      if (checkAwardId) {
        setAwardId(checkAwardId);
        storeAwardCookies(checkAwardId);
      } else if (cookie_award_id) {
        setAwardId(cookie_award_id);
        storeAwardCookies(cookie_award_id);
      } else if (failsafe_award_id) {
        setAwardId(failsafe_award_id);
        storeAwardCookies(failsafe_award_id);
      } else {
        defaultRedirect();
      }
    } else {

      let url = '';
      const domainParts = currentHost.split('.');
      if (domainParts.length >= 2) {
        setSection(domainParts[0]);
        url = domainParts.slice(1).join('.');
      } else {
        const message = 'An error occured. Invalid domain format.';
        CustomToast.error(message);
        defaultRedirect();
      }

      if (checkAwardId) {
        setAwardId(checkAwardId);
        storeAwardCookies(checkAwardId);
      } else {
        getAwardId(url).then(response => {
          const result = response.data;
          const awardIdGet = result.data.id;
          if (result.status == "Success") {
            setAwardId(awardIdGet);
            storeAwardCookies(awardIdGet);
          } else {
            CustomToast.defaultError();
            defaultRedirect();
          }
        }).catch(() => {
          CustomToast.defaultError();
          defaultRedirect();
        });
      }
    }
  }, []);

  useEffect(() => {
    checkSection();
    if (awardReady) {
      const pathNames = [
        "/register",
        "/award/book",
        "/award/book/form",
        '/award/book/form/seats',
        "/award/event",
        "/booking/summary",
        "/booking/details",
        "/booking/payment/success",
        "/booking/attendees",
        "/booking/attendees/setup",
        "/category/winner",
        "/award/winner",
        "/award/nomination",
        "/winner/summary",
        "/winner/payment/success",
        "/entry/payment/success"
      ];
      if (!currentUser) {
        if (!pathNames.includes(currentPathName)) {
          if ((!award_id && !section) || (award_id == "" && section == "")) {
            const firstPath = currentPathName.split('/')[1];
            if (firstPath && firstPath != "") {
              if (failsafe_award_id && failsafe_award_id != "") {
                resetCookies();
                setTimeout(function () {
                  history.push("/" + firstPath + "/login?award_id=" + failsafe_award_id);
                }, 500);
              } else {
                if (firstPath == "admin") {
                  resetCookies();
                  setTimeout(function () {
                    history.push("/" + firstPath + "/login");
                  }, 500);
                } else {
                  resetCookies();
                  defaultRedirect();
                }
              }
            } else {
              resetCookies();
              defaultRedirect();
            }
          } else {
            getAward().then(response => {
              const result = response.data.data;
              if (result) {
                setAwardStatus(() => result.status);
              } else {
                resetCookies();
                defaultRedirect();
              }
            }).catch(() => {
              CustomToast.defaultError();
            });
          }
        }
      } else {
        if (!(currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost")) {
          const domainParts = currentHost.split('.');
          const domainSection = domainParts[0];

          if (domainSection === 'entrant') {
            checkEntrantPortal();
          } else if (domainSection === 'judge') {
            checkJudgePortal();
          } else if (domainSection === 'coordinator') {
            checkCoordinatorPortal();
          } else if (domainSection === 'sponsor') {
            checkSponsorPortal();
          } else {
            checkEntrantPortal();
          }
        } else if (pathNames.includes(currentPathName)) {
          if (action === 'entry' && section === 'entrant' && currentPathName == "/register") {
            setTimeout(function () {
              history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
            }, 500);
          }
        } else {
          if (userRole == "Admin") {
            history.push('/admin');
          } else {
            if (!award_id && !section) {
              if (!cookie_award_id && !cookie_section) {
                const firstPath = currentPathName.split('/')[1];
                if (firstPath && firstPath != "") {
                  if (failsafe_award_id && failsafe_award_id != "") {
                    resetCookies();
                    setTimeout(function () {
                      history.push("/" + firstPath + "/login?award_id=" + failsafe_award_id);
                    }, 500);

                  } else {
                    if (firstPath == "admin") {
                      resetCookies();
                      setTimeout(function () {
                        history.push("/" + firstPath + "/login");
                      }, 500);
                    } else {
                      resetCookies();
                      defaultRedirect();
                    }
                  }
                } else {
                  resetCookies();
                  defaultRedirect();
                }
              } else {
                setCookie('award_id', cookie_award_id, { path: '/' });
                setCookie('section', cookie_section, { path: '/' });

                if (action === 'entry' && cookie_section === 'entrant') {
                  setTimeout(function () {
                    history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                  }, 500);
                } else if (entry_id && cookie_section === 'entrant') {
                  setTimeout(function () {
                    history.push("/entrant/entry/details?entry_id=" + entry_id);
                  }, 500);
                } else if (confirm_tr_id && cookie_section === 'entrant') {
                  setTimeout(function () {
                    history.push("/entrant/confirm/trophy-recipient?id=" + confirm_tr_id);
                  }, 500);
                } else {
                  setTimeout(function () {
                    history.push("/" + cookie_section + "/home");
                  }, 500);
                }

              }
            } else {
              if (!cookie_award_id && !cookie_section) {
                resetCookies();
                defaultRedirect();
              } else {
                if (category_id && category_id != "") {
                  setCookie('award_id', award_id, { path: '/' });
                  setCookie('section', section, { path: '/' });
                  if (action === 'entry') {
                    setTimeout(function () {
                      history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                    }, 500);
                  } else {
                    setTimeout(function () {
                      history.push("/entrant/category?category_id=" + category_id + "&from_website=1");
                    }, 500);
                  }
                } else {
                  if (section && award_id) {
                    setCookie('award_id', award_id, { path: '/' });
                    setCookie('section', section, { path: '/' });
                    if (action === 'entry' && section === 'entrant') {
                      setTimeout(function () {
                        history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                      }, 500);
                    } else if (entry_id && section === 'entrant') {
                      setTimeout(function () {
                        history.push("/entrant/entry/details?entry_id=" + entry_id);
                      }, 500);
                    } else if (confirm_tr_id && section === 'entrant') {
                      setTimeout(function () {
                        history.push("/entrant/confirm/trophy-recipient?id=" + confirm_tr_id);
                      }, 500);
                    } else {
                      setTimeout(function () {
                        history.push("/" + section + "/home");
                      }, 500);
                    }
                  } else {
                    if (cookie_section && cookie_award_id) {
                      setCookie('award_id', cookie_award_id, { path: '/' });
                      setCookie('section', cookie_section, { path: '/' });

                      if (action === 'entry' && cookie_section == 'entrant') {
                        setTimeout(function () {
                          history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                        }, 500);
                      } else if (entry_id && cookie_section === 'entrant') {
                        setTimeout(function () {
                          history.push("/entrant/entry/details?entry_id=" + entry_id);
                        }, 500);
                      } else if (confirm_tr_id && cookie_section === 'entrant') {
                        setTimeout(function () {
                          history.push("/entrant/confirm/trophy-recipient?id=" + confirm_tr_id);
                        }, 500);
                      } else {
                        setTimeout(function () {
                          history.push("/" + cookie_section + "/home");
                        }, 500);
                      }
                    } else {
                      setCookie('section', "entrant", { path: '/' });
                      if (action === 'entry') {
                        setTimeout(function () {
                          history.push("/entrant/submit-entry?award_id=" + award_id + "&section=" + section + "&action=" + action);
                        }, 500);
                      } else if (entry_id && cookie_section === 'entrant') {
                        setTimeout(function () {
                          history.push("/entrant/entry/details?entry_id=" + entry_id);
                        }, 500);
                      } else if (confirm_tr_id && cookie_section === 'entrant') {
                        setTimeout(function () {
                          history.push("/entrant/confirm/trophy-recipient?id=" + confirm_tr_id);
                        }, 500);
                      } else {
                        setTimeout(function () {
                          history.push("/entrant/home");
                        }, 500);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [awardReady]);

  return (
    <div id="bis-form">
      <ToastContainer style={{ width: "370px" }} />
      {authState === STATE_BIS_LOGIN && (
        <form onSubmit={handleSubmit(loginSubmit)} className="bis_login_form">
          {section === "entrant" && awardStatus === 'Closed' ?
            <>
              <p className="text-center">Register, download and share the images and videos from last night's awards ceremony!</p>
            </>
            :
            null
          }

          <p className="text-center form_login">MEMBER LOGIN</p>
          <div className="form-group">
            <input {...register("email", { required: "Email field is required" })} placeholder="Email Address" maxLength={80} type="email" className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-3"}`} />
            {errors.email ? <p role="alert" className="invalid-feedback">Email field is required</p> : null}
          </div>
          <FormGroup>
            <input {...register("password", { required: "Password field is required" })} placeholder="Password" maxLength={255} type="password" className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-3"}`} />
            {errors.email ? <p role="alert" className="invalid-feedback">Password field is required</p> : null}
          </FormGroup>
          {formStatus != "standby" ?
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              Logging in...
            </Button>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block
            >
              {renderButtonText()}
            </Button>
          }
          <div className='text-center'>
            <a className='bis-link' href={'/forgotpassword?email=' + email}>Forgot password?</a>
          </div>
          <div className="text-center mt-1">
            <a href="" className="bis-link" onClick={goToRegister}>
              Register
            </a>
          </div>
        </form>
      )}

      {authState === STATE_BIS_SIGNUP && (
        <Form onSubmit={handleSubmit(signupSubmit)} className="bis_signup_form container">
          <div className='text-center mt-3 mb-3'>
            <img
              src={BISlogoblue}
              className="rounded"
              style={{ height: 60 }}
              alt="logo"
            />
          </div>
          {section == "entrant" ?
            <>
              <p className="text-center form_login mb-0">ENTRY REGISTRATION</p>
              <p className="small text-muted text-center mb-4">Fill out your details below which will bring you to step 2 where you can then choose categories and upload your submission.{award_id != 47 && award_id != 67 && ' The Awards are free to enter.'}</p>
            </>
            :
            <p className="text-center form_login mb-0">MEMBER REGISTRATION</p>
          }

          <h3>LOGIN INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <FormGroup>
                <p className='small mb-1'>Email Address<span className="asterisk_red">*</span> </p>
                <input {...register("email", { required: true })} type="email" maxLength={80} className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-3"}`} />
                {errors.email ? <p role="alert" className="invalid-feedback">Email field is required</p> : null}
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Password<span className="asterisk_red">*</span></p>
                    <input {...register("password", { required: true })} type="password" maxLength={255} className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-0"}`} />
                    {errors.email ? <p role="alert" className="invalid-feedback mb-0">Password field is required</p> : null}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Confirm Password<span className="asterisk_red">*</span></p>
                    <input {...register("password_confirmation", { required: true })} type="password" maxLength={255} className={`form-control ${errors.password_confirmation ? "form-invalid mb-0" : "mb-0"}`} />
                    {errors.password_confirmation ? <p role="alert" className="invalid-feedback  mb-0">Password confirmation field is required</p> : null}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3>PERSONAL INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <p className='small mb-1'>First Name<span className="asterisk_red">*</span></p>
              <input {...register("first_name", { required: true })} type="text" maxLength={40} className={`form-control ${errors.first_name ? "form-invalid mb-0" : "mb-0"}`} />
              {errors.first_name ? <p role="alert" className="invalid-feedback mb-0">First Name field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Last Name<span className="asterisk_red">*</span></p>
              <input {...register("last_name", { required: true })} type="text" maxLength={40} className={`form-control ${errors.last_name ? "form-invalid mb-0" : "mb-0"}`} />
              {errors.last_name ? <p role="alert" className="invalid-feedback mb-0">Last Name field is required</p> : null}
            </Col>
          </Row>
          <h3>COMPANY DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <p className='small mb-1'>Company<span className="asterisk_red">*</span></p>
              <input {...register("company_name", { required: true })} type="text" maxlength={150} className={`form-control ${errors.company_name ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_name ? <p role="alert" className="invalid-feedback">Company field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Position/Job Title<span className="asterisk_red">*</span></p>
              <input {...register("company_position", { required: true })} type="text" maxlength={150} className={`form-control ${errors.company_position ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_position ? <p role="alert" className="invalid-feedback">Company Position field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Industry<span className="asterisk_red">*</span></p>
              <select {...register("company_industry", { required: true })} maxLength={50} className={`form-control ${errors.company_industry ? "form-invalid mb-0" : "mb-3"}`}>
                {industries.map(industry => (
                  <option value={industry}>{industry}</option>
                ))}
              </select>
              {errors.company_industry ? <p role="alert" className="invalid-feedback">Industry field is required</p> : null}
            </Col>
            <Col lg={12}>
              <p className='small mb-1'>Company Phone Number<span className="asterisk_red">*</span></p>
              <input {...register("company_phone", { required: true })} type="tel" maxLength={150} className={`form-control ${errors.company_phone ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_phone ? <p role="alert" className="invalid-feedback">Company Phone Number field is required</p> : null}
            </Col>
          </Row>
          {formStatus != "standby" ?
            <>
              {authState === STATE_BIS_LOGIN ?
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Logging in...
                </Button>
                :
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Signing up...
                </Button>
              }
            </>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              {renderButtonText()}
            </Button>
          }
          <div className="text-center">
            <span className="mr-2">Already have an account?</span>
            <a href="" className="bis-link" onClick={goToLogin}>
              Login
            </a>
          </div>
        </Form>
      )}
      {children}
    </div>

  );
}


export const STATE_BIS_LOGIN = 'LOGIN';
export const STATE_BIS_SIGNUP = 'SIGNUP';

export default BisAuthForm;
